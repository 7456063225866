export default {
  BUTTON_PRIMARY_CLASS: 'button button--small button--primary',
  BUTTON_SECONDARY_CLASS: 'button button--small button--secondary',

  SEARCH_BOX_BUTTON_CLASS: 'search__button input__field_form_button button--primary',
  SEARCH_BOX_PLACEHOLDER: 'Search our store',

  CHANGE_VEHICLE: 'Change',
  DISCARD_VEHICLE: 'Clear',

  PRODUCT_REPEATER_CLASS: 'row row-cols-lg-3 row-cols-md-3  row-cols-2',
  RELATED_PRODUCT_LIST_CLASS: 'row row-cols-lg-3 row-cols-md-3  row-cols-2',

  ADD_VEHICLE_BUTTON_CLASS: 'button--full-width',
  GARAGE_TITLE: '',
  GARAGE_SIZE: '<span class="garage-size header__actions_btn_cart_num" key="garage-size">{{size}}</span>',
};

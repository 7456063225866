//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-772:_5364,_3908,_3472,_192,_508,_6496,_4868,_2308;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-772')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-772', "_5364,_3908,_3472,_192,_508,_6496,_4868,_2308");
        }
      }catch (ex) {
        console.error(ex);
      }
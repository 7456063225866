const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel', 'Drive'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/collections';

const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  return fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
}

const getActiveCurrency = () => globalThis.Shopify.currency?.active;

function formatPrice(price) {
  if (!price) {
    return '';
  }

  const activeCurrency = getActiveCurrency();
  const locale = `${window.Shopify.locale.split('-')[0]}-${window.Shopify.country}`;

  const num = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: activeCurrency,
    currencyDisplay: 'narrowSymbol',
  }).format(price);
  return `${num} ${activeCurrency}`;
}

function InitFunc() {
  if (window.document.querySelector('#cm_results, #cm-brand-list')) {
    const unwantedPageWrappers = [['.container-fluid .rte', 'rte']];

    unwantedPageWrappers.forEach(([elemSelector, className]) => {
      const wrapperElem = window.document.querySelector(elemSelector);
      wrapperElem?.classList.remove(className);
    });
  }
}

function AfterInit() {
  const slider = document.querySelector('.cm_vehicle-categories_category-wrapper-repeater');

  let mouseDown = false;
  let mouseDrag = false;
  let anchorClickAttached = false;
  let startX;
  let scrollLeft;

  const preventClick = (e) => {
    if (mouseDrag) {
      e.preventDefault();
    }
  };

  const startDragging = (e) => {
    mouseDown = true;
    startX = e.pageX - slider.offsetLeft;
    ({ scrollLeft } = slider);
    if (!anchorClickAttached) {
      slider.querySelectorAll('a').forEach((elem) => elem.addEventListener('click', preventClick));
    }
    anchorClickAttached = true;
  };

  const stopDragging = () => {
    mouseDown = false;
  };

  const move = (e) => {
    e.preventDefault();
    mouseDrag = false;
    if (!mouseDown) {
      return;
    }
    mouseDrag = true;
    const x = e.pageX - slider.offsetLeft;
    const scroll = x - startX;
    slider.scrollLeft = scrollLeft - scroll;
  };

  slider.addEventListener('pointermove', move);
  slider.addEventListener('pointerdown', startDragging);
  slider.addEventListener('pointerup', stopDragging);
  slider.addEventListener('pointerleave', stopDragging);
}

export default {
  platform: 'shopify',
  InitFunc,
  AfterInit,
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      {
        pathname: window.document.querySelector('#cm-brand-list') ? window.location.pathname : null,
        field: 'vendor',
      },
    ],
  },
  SearchRequestDefaults: {
    pageSize: 36,
  },
  product: {
    formatPrice,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: '#cm-subcategory-list',
      template: 'facetTiles/subtitles',
      facetField: 'category',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'SearchBox',
      type: 'SearchBoxDialogButton',
      location: { selector: 'predictive-search.search-modal__form', class: 'cm-search-box' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        selector: '#cm-mobile-search-box',
        class: 'cm-mobile-search-box',
      },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: 'advanced-search-filter',
      template: 'fitmentSearch/homeVehicleWidget',
      isAlwaysColumnLayout: true,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/collapsibleVehicleWidget',
      columnBreakpoint: 750,
      initCollapsed: true,
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
    {
      name: 'Garage',
      location: '#cm-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: '#cm-mobile-garage',
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '#cm-collection-list',
      template: 'facetTiles/tabs',
      isMultiLevel: true,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm-brand-list',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
      isVehicleWidgetDisabled: !!window.document.querySelector('#cm-ymm'),
    },
    {
      name: 'RelatedItems',
      location: '#cm-related-products',
      template: 'product/relatedItems',
      count: 6,
      getSelection,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
      fields: !!window.Convermax.config?.extraFieldsAsFilters && baseFitmentFields,
    },
  ],
};
